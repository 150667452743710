import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import { InitialLoading } from './InitialLoading';
import { CommunicationContainer } from './CommunicationContainer';
import { Navigation } from './navigation/Navigation';
import { identify, initAnalytics, track, updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { CONFIG } from '@magicyard/utils';
import { CombinedYard, Display, generateRandomControllers, YardWithGame } from '@magicyard/shared/platform/lib/api';
import { Communication } from '@magicyard/shared/platform/hooks/usePlatformDisplayTypes';
import useForceScale from '@magicyard/shared/platform/hooks/useForceScale';
import { usePlatformDisplayInternal } from '@magicyard/shared/platform/hooks/usePlatformDisplayInternal';
import { preloadAllRowContent } from '@magicyard/platform-shared/rows';

import { ReconnectionMessage } from '@magicyard/platform-shared/src/ReconnectionMessage';
import { useBackButton } from './navigation/useBackButton';
import { Maintenance } from './Maintenance';
import { FIRST_TIME_KEY, MAINTENANCE_MODE, DEFAULT_GAME_ID } from './config';
// import { QrOverlay } from './QrOverlay';

export interface AppProps {
  matchID: string;
}

export interface DisplayMainLoaderProps {
  yard: YardWithGame;
}

interface PlatformReturn {
  navigationData?: {
    yard: CombinedYard;
    display: Display;
    setGameId: (gameId: string | null) => void;
    isPlayingGame: boolean;
    communication: Communication;
  };
  toRender?: React.ReactNode;
  isReconnecting?: boolean;
}

export const App: React.ReactNode = () => {
  useForceScale();
  const isFirstTime = useRef(localStorage.getItem(FIRST_TIME_KEY));
  const [didLoad, setDidLoad] = useState<boolean | 'waiting'>(false);
  const [bypassMaintenanceCount, setBypassMaintenanceCount] = useState(0);

  const showMaintenance = MAINTENANCE_MODE && bypassMaintenanceCount < 5;

  useEffect(() => {
    function onEvent(event: KeyboardEvent) {
      if (event.key === 'ArrowUp') {
        setBypassMaintenanceCount((old) => old + 1);
      }
    }

    document.addEventListener('keydown', onEvent);

    return () => {
      document.removeEventListener('keydown', onEvent);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(FIRST_TIME_KEY, 'false');
    initAnalytics(CONFIG.MIXPANEL_API_KEY, 'Display', 'Platform', undefined);
    track(`Magicyard Platform Launched`);
    window.onload = (event) => {
      preloadAllRowContent();
      setDidLoad(true);

      // setDidLoad('waiting');
      setTimeout(() => {
        setDidLoad(true);
      }, 6000);
    };
  }, []);

  const query = new URLSearchParams(window.location.search);
  const platformReturn = usePlatformDisplayInternal<PlatformReturn, { url: string }>(
    {
      onLoading() {
        return { toRender: <InitialLoading toTrack={'Initial Loading'} /> };
      },
      onGameSelection({ yard, display, setGameId, communication, isReconnecting }) {
        updateAnalyticsData({ yardId: yard.id });
        return {
          navigationData: {
            yard: yard,
            display: display,
            setGameId: setGameId,
            isPlayingGame: false,
            communication: communication,
          },
          isReconnecting: isReconnecting,
        };
      },
      onYardAndDisplay({ yard, display, setGameId, communication, isReconnecting }) {
        return {
          navigationData: {
            yard: yard,
            display: display,
            setGameId: setGameId,
            isPlayingGame: false,
            communication: communication,
          },
          isReconnecting: isReconnecting,
        };
      },
      onLoadingGame({ yard, display, communication, isReconnecting }) {
        return {
          navigationData: {
            isPlayingGame: true,
            setGameId: () => undefined,
            yard: yard,
            display: display,
            communication: communication,
          },
          toRender: <InitialLoading />,
          isReconnecting: isReconnecting,
        };
      },
      onQueue({ yard, display }) {
        throw new Error('TODO QUEUE');
      },
      onGame({ yard, gameStartArgs, display, onCloseGame, communication, isReconnecting }) {
        if (yard.gameId === 'poker') {
          identify(display.id);
        }

        return {
          navigationData: {
            isPlayingGame: true,
            setGameId: () => undefined,
            yard: yard,
            display: display,
            communication: communication,
          },
          toRender: (
            <Game
              yard={yard}
              gameStartArgs={gameStartArgs}
              display={display}
              onCloseGame={onCloseGame}
              communication={communication}
            />
          ),
          isReconnecting: isReconnecting,
        };
      },
    },
    { displayId: query.get('displayID') },
    isFirstTime.current === null ? DEFAULT_GAME_ID : undefined
  );

  if (platformReturn.navigationData?.yard.type === 'withQueue') {
    throw new Error('not yet handled state (queue)');
  }

  if (platformReturn.navigationData !== undefined) {
    updateAnalyticsData({ numOfPlayers: platformReturn.navigationData.yard.controllers.length });
  }

  if (showMaintenance) {
    return <Maintenance toTrack={'Maintenance'} />;
  } else {
    return (
      <>
        {(didLoad === false || didLoad === 'waiting') && (
          <InitialLoading toTrack={'Initial Loading'} showBar={didLoad === 'waiting'} />
        )}
        <ReconnectionMessage isReconnecting={platformReturn.isReconnecting} />
        {platformReturn.navigationData !== undefined && (
          <>
            {/*<QrOverlay*/}
            {/*  controllers={platformReturn.navigationData.yard.controllers}*/}
            {/*  display={platformReturn.navigationData.display}*/}
            {/*  listener={platformReturn.navigationData.communication.receiveNavigationCommand}*/}
            {/*/>*/}
            <Navigation
              yard={platformReturn.navigationData.yard}
              display={platformReturn.navigationData.display}
              setGameId={platformReturn.navigationData.setGameId}
              isPlayingGame={platformReturn.navigationData.isPlayingGame}
              communication={platformReturn.navigationData.communication}
            />
            {platformReturn.navigationData.yard.type === 'withGame' ? undefined : (
              <CommunicationContainer
                controllers={platformReturn.navigationData.yard.controllers}
                receiveReaction={platformReturn.navigationData.communication.receiveReaction}
              />
            )}
          </>
        )}
        <div className={'app_game-container'}>{platformReturn.toRender}</div>
      </>
    );
  }
};

const Game = ({
  yard,
  gameStartArgs,
  display,
  onCloseGame,
  communication,
}: {
  yard: YardWithGame;
  gameStartArgs: { url: string };
  display: Display;
  onCloseGame: () => void;
  communication: Communication;
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleBackButton = useCallback(() => {
    setIsClosing(true);
  }, []);

  useBackButton(handleBackButton);

  useEffect(() => {
    track('Game Started');
  }, []);

  const url = new URL(gameStartArgs.url);
  const gameParams = new URLSearchParams(url.search);
  const existingParams = new URLSearchParams(window.location.search);
  existingParams.forEach((value, key) => {
    gameParams.append(key, value);
  });

  url.search = gameParams.toString();
  return (
    <>
      <iframe
        onLoad={() => setIsLoading(false)}
        allow={'screen-wake-lock *; fullscreen *; autoplay *; web-share *; gamepad *; '}
        className={'app_iframe ' + (isClosing ? 'app_tv-close-container' : 'app_tv-open-container')}
        onAnimationEnd={(e) => {
          if (e.animationName === 'shutdown') {
            onCloseGame();
          }
        }}
        src={url.toString()}
        title={yard.gameId}
      />
      <div style={{ width: '100%', height: '100%', top: 0, zIndex: 2, position: 'absolute' }} />
      {isLoading && <InitialLoading />}
    </>
  );
};
