import { useEffect } from 'react';
import { App } from '@capacitor/app';

/** @todo push state */

export const useBackButton = (callback: () => void): void => {
  useEffect(() => {
    console.debug('Registering back button listener', { callback });
    let cleanup: () => void;
    const handleKeyUp = (event: KeyboardEvent): void => {
      if (event.key === 'Backspace' || event.keyCode === 461 || event.which === 461 /* LG Remote Back Button */) {
        callback();
      }
    };
    window.addEventListener('keydown', handleKeyUp);
    App.addListener('backButton', callback).then((value) => {
      cleanup = value.remove;
    });
    return () => {
      console.debug('Un-registering back button listener', { callback });
      if (cleanup) cleanup();
      window.removeEventListener('keydown', handleKeyUp);
    };
  }, [callback]);
};
