import React, { useEffect, useState } from 'react';
import { Reaction } from '@magicyard/shared/platform/lib/api';
import { BaseController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import './CommunicationContainer.css';

export function useEvent<T>(event: (handler: (data: T) => void) => () => void, handler: (data: T) => void) {
  useEffect(() => {
    const off = event(handler);
    return () => {
      off();
    };
  }, [event, handler]);
}

export const useReaction = (receiveReaction: (listener: (reaction: Reaction) => void) => () => void) => {
  const [reactionForKey, setReactionForKey] = useState<{
    [key: string]: { reaction: Reaction; trajectory: { x: number; y: number } };
  }>({});
  useEvent(receiveReaction, (reaction) => {
    setReactionForKey((old) => ({
      ...old,
      [reaction.id]: { reaction: reaction, trajectory: { x: Math.random(), y: Math.random() } },
    }));
  });
  const deleteReactionForKey = (key: string) =>
    setReactionForKey((old) => {
      const newState = { ...old };
      delete newState[key];
      return newState;
    });

  return { reactionForKey, deleteReactionForKey };
};

export const CommunicationContainer = ({
  receiveReaction,
  controllers,
}: {
  receiveReaction: (listener: (reaction: Reaction) => void) => () => void;
  controllers: BaseController[];
}) => {
  const { reactionForKey, deleteReactionForKey } = useReaction(receiveReaction);
  return (
    <div className={'comm_container-root'}>
      {Object.keys(reactionForKey).map((key) => {
        const curr = reactionForKey[key];
        return (
          <div key={key} className={'comm_container-reaction_root'} style={{ left: curr.trajectory.x * 100 + '%' }}>
            <div
              className={'comm_container-reaction_avatar'}
              style={{
                backgroundImage: `url(${
                  controllers.find((c) => c.profile.id === curr.reaction.controller_id)?.profile.avatarUrl
                })`,
              }}
            />
            <div className={'comm_container-reaction'} onAnimationEnd={() => deleteReactionForKey(key)}>
              {curr.reaction.payload}
            </div>
          </div>
        );
      })}
    </div>
  );
};
