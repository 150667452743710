import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { App as CapacitorApp } from '@capacitor/app';
import { KeepAwake } from '@capacitor-community/keep-awake';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { CONFIG } from '@magicyard/utils';
import { initSentry } from '@magicyard/shared/src/sentry';

// import { StartAppUpdate } from './versionManager';
// StartAppUpdate();

initSentry();

KeepAwake.keepAwake();
CapacitorApp.addListener('appStateChange', ({ isActive }) => {
  console.log(`App state changed, isActive: ${isActive}`);
  if (isActive) {
    KeepAwake.keepAwake();
  } else {
    KeepAwake.allowSleep();
  }
});

console.log('Starting with config:\n', { CONFIG });
const getDemoData = (): { matchId: string; displayId: string | null } | null => {
  const params = new URLSearchParams(window.location.search);
  const matchId = params.get('matchID');
  const displayId = params.get('displayId');
  if (matchId === null) {
    return null;
  }

  return { matchId: matchId, displayId: displayId };
};
const demoData = getDemoData();
console.log({ demoData });

ReactDOM.render(
  <React.StrictMode>
    {/*@ts-ignore TODO */}
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
