import { Display } from '@magicyard/shared/platform/lib/api';
import { QrWithPlaceholder } from './GameDetails';
import { useBackButton } from './useBackButton';

const JoinOrInviteScreen = ({ onBack, display }: { onBack: () => void; display: Display }) => {
  useBackButton(onBack);

  return (
    <div
      style={{
        position: 'absolute',
        color: 'white',
        zIndex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ position: 'absolute', left: '33%', bottom: '33%' }}>
        <div style={{ position: 'absolute', top: -415, width: 314, textAlign: 'center' }}>
          Scan and tap '<span style={{ color: '#ff0071' }}>Invite friends</span>'
        </div>
        <QrWithPlaceholder display={display} title={''} />
      </div>
      <div style={{ marginLeft: 400, width: '5px', height: 600, background: 'white' }} />
      <div
        style={{
          position: 'absolute',
          right: 300,
          bottom: '33%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <div style={{ position: 'absolute', top: -140, width: 314, textAlign: 'center' }}>
          Input the code in to your device
        </div>
        <div>
          <div className={'app-pin_code_lg'} />
          <span style={{ color: '#ff0071', fontSize: 86 }}>{display.code}</span>
        </div>
      </div>
    </div>
  );
};

export default JoinOrInviteScreen;
