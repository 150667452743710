import { assert } from '@magicyard/utils/typeUtils';

import thumb_catan from './assets/catan/thumbnail.webp';
import thumb_monopoly from './assets/monopoly/thumbnail.webp';
import thumb_jeopardy from './assets/jeopardy/thumbnail.webp';
import thumb_drawful from './assets/drawful/thumbnail.webp';
import thumb_blanksy from './assets/blanksy/thumbnail.webp';
import thumb_bridge from './assets/bridge/thumbnail.webp';
import thumb_overcooked from './assets/overcooked/thumbnail.webp';
import thumb_warewolf from './assets/warewolf/thumbnail.webp';
import thumb_scrabble from './assets/scrabble/thumbnail.webp';
import thumb_codenames from './assets/codenames/thumbnail.webp';
import thumb_codenamesduet from './assets/codenamesduet/thumbnail.webp';
import thumb_dixit from './assets/dixit/thumbnail.webp';
import thumb_explodingkittens from './assets/explodingkittens/thumbnail.webp';
import thumb_fibbage from './assets/fibbage/thumbnail.webp';
import thumb_kahootanimals from './assets/kahootanimals/thumbnail.webp';
import thumb_kahootdisney from './assets/kahootdisney/thumbnail.webp';
import thumb_kahootnba from './assets/kahootnba/thumbnail.webp';
import thumb_kahootstarwars from './assets/kahootstarwars/thumbnail.webp';
import thumb_kahootworldcup from './assets/kahootworldcup/thumbnail.webp';
import thumb_kahootmarvel from './assets/kahootmarvel/thumbnail.webp';
import thumb_pandemic from './assets/pandemic/thumbnail.webp';
import thumb_poker from './assets/poker/thumbnail.webp';
import thumb_risk from './assets/risk/thumbnail.webp';
import thumb_sevenwonders from './assets/sevenwonders/thumbnail.webp';
import thumb_sevenwondersduel from './assets/sevenwondersduel/thumbnail.webp';
import thumb_takenoko from './assets/takenoko/thumbnail.webp';
import thumb_ticket_to_ride from './assets/ticket-to-ride/thumbnail.webp';
import thumb_uno from './assets/uno/thumbnail.webp';
import thumb_wordsonstream from './assets/wordsonstream/thumbnail.webp';
import thumb_justdancenow from './assets/justdancenow/thumbnail.webp';
import thumb_selfiegames from './assets/selfiegames/thumbnail.webp';
import thumb_sushigo from './assets/sushigo/thumbnail.webp';
import thumb_forbiddenisland from './assets/forbiddenisland/thumbnail.webp';
import thumb_wreckless from './assets/wreckless/thumbnail.webp';
import thumb_doodledash from './assets/doodledash/thumbnail.webp';
import thumb_gptrivia from './assets/gptrivia/thumbnail.webp';
import thumb_triviacelebrity from './assets/triviacelebrity/thumbnail.webp';
import thumb_triviasports from './assets/triviasports/thumbnail.webp';
import thumb_triviadisney from './assets/triviadisney/thumbnail.webp';
import thumb_triviahistory from './assets/triviahistory/thumbnail.webp';
import thumb_triviamarvel from './assets/triviamarvel/thumbnail.webp';
import thumb_triviamusic from './assets/triviamusic/thumbnail.webp';
import thumb_trivianba from './assets/trivianba/thumbnail.webp';
import thumb_triviastarwars from './assets/triviastarwars/thumbnail.webp';
import thumb_triviawildlife from './assets/triviawildlife/thumbnail.webp';
import thumb_triviasoccer from './assets/triviasoccer/thumbnail.webp';
import thumb_triviaharrypotter from './assets/triviaharrypotter/thumbnail.webp';
import thumb_magicblocks from './assets/magicblocks/thumbnail.webp';

import bg_catan from './assets/catan/bg.webp';
import bg_monopoly from './assets/monopoly/bg.webp';
import bg_jeopardy from './assets/jeopardy/bg.webp';
import bg_drawful from './assets/drawful/bg.webp';
import bg_blanksy from './assets/blanksy/bg.webp';
import bg_bridge from './assets/bridge/bg.webp';
import bg_overcooked from './assets/overcooked/bg.webp';
import bg_warewolf from './assets/warewolf/bg.webp';
import bg_scrabble from './assets/scrabble/bg.webp';
import bg_codenames from './assets/codenames/bg.webp';
import bg_codenamesduet from './assets/codenamesduet/bg.webp';
import bg_dixit from './assets/dixit/bg.webp';
import bg_explodingkittens from './assets/explodingkittens/bg.webp';
import bg_fibbage from './assets/fibbage/bg.webp';
import bg_kahootanimals from './assets/kahootanimals/bg.webp';
import bg_kahootdisney from './assets/kahootdisney/bg.webp';
import bg_kahootnba from './assets/kahootnba/bg.webp';
import bg_kahootstarwars from './assets/kahootstarwars/bg.webp';
import bg_kahootworldcup from './assets/kahootworldcup/bg.webp';
import bg_kahootmarvel from './assets/kahootmarvel/bg.webp';
import bg_pandemic from './assets/pandemic/bg.webp';
import bg_poker from './assets/poker/bg.webp';
import bg_risk from './assets/risk/bg.webp';
import bg_sevenwonders from './assets/sevenwonders/bg.webp';
import bg_sevenwondersduel from './assets/sevenwondersduel/bg.webp';
import bg_takenoko from './assets/takenoko/bg.webp';
import bg_ticket_to_ride from './assets/ticket-to-ride/bg.webp';
import bg_uno from './assets/uno/bg.webp';
import bg_wordsonstream from './assets/wordsonstream/bg.webp';
import bg_justdancenow from './assets/justdancenow/bg.webp';
import bg_selfiegames from './assets/selfiegames/bg.webp';
import bg_sushigo from './assets/sushigo/bg.webp';
import bg_forbiddenisland from './assets/forbiddenisland/bg.webp';
import bg_wreckless from './assets/wreckless/bg.webp';
import bg_doodledash from './assets/doodledash/bg.webp';
import bg_gptrivia from './assets/gptrivia/bg.webp';
import bg_triviacelebrity from './assets/triviacelebrity/bg.webp';
import bg_triviasports from './assets/triviasports/bg.webp';
import bg_triviadisney from './assets/triviadisney/bg.webp';
import bg_triviahistory from './assets/triviahistory/bg.webp';
import bg_triviamarvel from './assets/triviamarvel/bg.webp';
import bg_triviamusic from './assets/triviamusic/bg.webp';
import bg_trivianba from './assets/trivianba/bg.webp';
import bg_triviastarwars from './assets/triviastarwars/bg.webp';
import bg_triviawildlife from './assets/triviawildlife/bg.webp';
import bg_triviasoccer from './assets/triviasoccer/bg.webp';
import bg_triviaharrypotter from './assets/triviaharrypotter/bg.webp';
import bg_magicblocks from './assets/magicblocks/bg.webp';

import logo_blanksy from './assets/blanksy/logo.webp';
import logo_doodledash from './assets/doodledash/logo.webp';
import logo_magicblocks from './assets/magicblocks/logo.webp';
import logo_magicpoker from './assets/poker/logo.webp';
import logo_selfiegames from './assets/selfiegames/logo.webp';
import logo_gptrivia from './assets/gptrivia/logo.webp';
import logo_titckettoride from './assets/ticket-to-ride/logo.webp';
import logo_wordsonstream from './assets/wordsonstream/logo.webp';

// ****************  DEFINITIONS  **************** //

export const GAME_IDS_WITH_VARIANTS = {
  catan: [] as const,
  codenames: [] as const,
  codenamesduet: [] as const,
  dixit: [] as const,
  explodingkittens: [] as const,
  fibbage: [] as const,
  kahootanimals: [] as const,
  kahootdisney: [] as const,
  kahootnba: [] as const,
  kahootstarwars: [] as const,
  kahootworldcup: [] as const,
  kahootmarvel: [] as const,
  pandemic: [] as const,
  poker: [] as const,
  risk: [] as const,
  sevenwonders: [] as const,
  sevenwondersduel: [] as const,
  takenoko: [] as const,
  'ticket-to-ride': ['dev'] as const,
  uno: [] as const,
  wordsonstream: [] as const,
  justdancenow: [] as const,
  monopoly: [] as const,
  jeopardy: [] as const,
  scrabble: [] as const,
  drawful: [] as const,
  blanksy: [] as const,
  bridge: [] as const,
  overcooked: [] as const,
  werewolf: [] as const,
  selfiegames: [] as const,
  sushigo: [] as const,
  forbiddenisland: [] as const,
  wreckless: [] as const,
  doodledash: [] as const,
  gptrivia: [
    'soccer',
    'wildlife',
    'starwars',
    'nba',
    'music',
    'marvel',
    'history',
    'disney',
    'sports',
    'celebrity',
    'harrypotter',
  ] as const,
  magicblocks: [] as const,
  magicsnakes: [] as const,
};

export const GAMES: { [key in BaseGameID]: BaseGame } = {
  wordsonstream: {
    id: 'wordsonstream',
    name: 'Words on Stream',
    thumbnail: thumb_wordsonstream,
    bg: bg_wordsonstream,
    logo: logo_wordsonstream,
    tags: 'Family, Educational, Easy To Learn',
    description: 'Beat the timer to form words from mixed letters displayed on the screen.',
    minPlayers: 1,
    maxPlayers: 20,
    duration: '15 Min',
    launchType: 'script',
    launchScriptId: 'wos',
    controllerArgs: ['noIframe'],
    isPlayable: true,
  },
  catan: {
    id: 'catan', // Make sure this is unique and lowercase
    name: 'Catan',
    thumbnail: thumb_catan,
    bg: bg_catan,
    tags: 'Strategy, Families, Friends',
    description: 'Collect and trade resources to build up the island of Catan in this modern classic.',
    minPlayers: 1,
    maxPlayers: 4,
    duration: '30-120 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },

  monopoly: {
    id: 'monopoly', // Make sure this is unique and lowercase
    name: 'Monopoly',
    thumbnail: thumb_monopoly,
    bg: bg_monopoly,
    tags: 'Family, Easy To Learn',
    description: 'In this competitive real estate market, there is only one possible outcome: Monopoly!',
    minPlayers: 2,
    maxPlayers: 8,
    duration: '60-180 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  jeopardy: {
    id: 'jeopardy', // Make sure this is unique and lowercase
    name: 'Jeopardy',
    thumbnail: thumb_jeopardy,
    bg: bg_jeopardy,
    tags: 'Family, Educational, Easy To Learn',
    description: 'Test the depth and breadth of your knowledge with this classic challenge!',
    minPlayers: 3,
    maxPlayers: 5,
    duration: '45 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  drawful: {
    id: 'drawful', // Make sure this is unique and lowercase
    name: 'Drawful2',
    thumbnail: thumb_drawful,
    bg: bg_drawful,
    tags: 'Social, Party, Casual',
    description: 'The game of terrible drawings and hilariously wrong answers.',
    minPlayers: 3,
    maxPlayers: 8,
    duration: '30 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  blanksy: {
    id: 'blanksy', // Make sure this is unique and lowercase
    name: 'Blanksy',
    thumbnail: thumb_blanksy,
    bg: bg_blanksy,
    logo: logo_blanksy,
    tags: 'Social, Party, Casual',
    description: 'The game of terrible drawings and hilariously wrong answers.',
    minPlayers: 3,
    maxPlayers: 5,
    duration: '15 Min',
    launchType: 'bgio',
    isPlayable: true,
    magicyardGame: { importPath: '' },
  },
  bridge: {
    id: 'bridge', // Make sure this is unique and lowercase
    name: 'Bridge',
    thumbnail: thumb_bridge,
    bg: bg_bridge,
    tags: 'Family, Party, Boomers',
    description: 'Bid to communicate and to set trump. Then take tricks to fulfill your bid.',
    minPlayers: 4,
    maxPlayers: 4,
    duration: '60 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  overcooked: {
    id: 'overcooked', // Make sure this is unique and lowercase
    name: 'Overcooked',
    thumbnail: thumb_overcooked,
    bg: bg_overcooked,
    tags: 'Social, Party, Family',
    description: 'Overcooked is a chaotic couch co-op cooking game for one to four players.',
    minPlayers: 1,
    maxPlayers: 4,
    duration: '30 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  werewolf: {
    id: 'werewolf', // Make sure this is unique and lowercase
    name: 'Werewolf',
    thumbnail: thumb_warewolf,
    bg: bg_warewolf,
    tags: 'Social, Party, Family',
    description:
      'Werewolf (also known as Mafia) takes place in a small town where a minority of the townsfolk hold a dangerous secret.',
    minPlayers: 8,
    maxPlayers: 24,
    duration: '20-30 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  scrabble: {
    id: 'scrabble', // Make sure this is unique and lowercase
    name: 'Scrabble',
    thumbnail: thumb_scrabble,
    bg: bg_scrabble,
    tags: 'Social, Party, Family',
    description: 'Family, Educational, Easy To Learn',
    minPlayers: 2,
    maxPlayers: 4,
    duration: '90 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  codenames: {
    id: 'codenames', // Make sure this is unique and lowercase
    name: 'Codenames',
    thumbnail: thumb_codenames,
    bg: bg_codenames,
    tags: 'Social, Team Vs Team',
    description: 'Give your team clever one-word clues to help them spot their agents in the field.',
    minPlayers: 2,
    maxPlayers: 8,
    duration: '15 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  codenamesduet: {
    id: 'codenamesduet', // Make sure this is unique and lowercase
    name: 'Codenames Duet',
    thumbnail: thumb_codenamesduet,
    bg: bg_codenamesduet,
    tags: 'Co-Op, Couples',
    description: 'Swap one-word coded clues with your partner, trying to identify specific word cards.',
    minPlayers: 2,
    maxPlayers: 2,
    duration: '15 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  dixit: {
    id: 'dixit', // Make sure this is unique and lowercase
    name: 'Dixit',
    thumbnail: thumb_dixit,
    bg: bg_dixit,
    tags: 'Families, Creativity, Easy To Learn',
    description: 'Give the perfect clue so most (not all) players guess the right surreal image card.',
    minPlayers: 3,
    maxPlayers: 6,
    duration: '30 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  explodingkittens: {
    id: 'explodingkittens', // Make sure this is unique and lowercase
    name: 'Exploding Kittens',
    thumbnail: thumb_explodingkittens,
    bg: bg_explodingkittens,
    tags: 'Easy To Learn, Friends, Social, Funny',
    description: 'Ask for favors, attack friends, see the future - whatever it takes to avoid exploding!',
    minPlayers: 1,
    maxPlayers: 5,
    duration: '15 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  fibbage: {
    id: 'fibbage', // Make sure this is unique and lowercase
    name: 'Fibbage',
    thumbnail: thumb_fibbage,
    bg: bg_fibbage,
    tags: 'Friends, Social, Funny',
    description: 'Fool your friends with your lies, avoid theirs, and find the (usually outrageous) truth.',
    minPlayers: 2,
    maxPlayers: 8,
    duration: '30 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  kahootanimals: {
    id: 'kahootanimals', // Make sure this is unique and lowercase
    name: 'Animals Kahoot!',
    thumbnail: thumb_kahootanimals,
    bg: bg_kahootanimals,
    tags: 'Family, Educational, Childrens',
    description:
      '"Ridiculously Fast Animals" quiz. This Interactive Quiz uses animated gifs to teach facts about animals.',
    minPlayers: 2,
    maxPlayers: 10,
    duration: '10-15 Min, 22 Questions',
    launchType: 'script',
    launchScriptId: 'kahoot',
    launchScriptArg: {
      url: 'https://play.kahoot.it/v2/?quizId=0e0cef99-a682-43e3-a113-a61e397611e4',
    },
    isPlayable: true,
  },
  kahootdisney: {
    id: 'kahootdisney', // Make sure this is unique and lowercase
    name: 'Disney Kahoot!',
    thumbnail: thumb_kahootdisney,
    bg: bg_kahootdisney,
    tags: 'Family, Educational, Childrens',
    description:
      'How well do you know the Disney Princesses and their signature songs? Don`t be afraid to sing these timeless classics aloud!',
    minPlayers: 2,
    maxPlayers: 10,
    duration: '10-15 Min, 13 Questions',
    launchType: 'script',
    launchScriptId: 'kahoot',
    launchScriptArg: {
      url: 'https://play.kahoot.it/v2/?quizId=d449de87-c9fc-4093-9e2b-94b665e985b8',
    },
    isPlayable: true,
  },
  kahootnba: {
    id: 'kahootnba', // Make sure this is unique and lowercase
    name: 'NBA Kahoot!',
    thumbnail: thumb_kahootnba,
    bg: bg_kahootnba,
    tags: 'Family, Educational, Sports',
    description: 'Test your NBA knowledge through Kahoot! Share about a favorite NBA moment, player, or team.',
    maxPlayers: 10,
    duration: '10-15 Min, 15 Questions',
    launchType: 'script',
    launchScriptId: 'kahoot',
    launchScriptArg: {
      url: 'https://play.kahoot.it/v2/?quizId=b4662d8c-48e1-4fd5-a5a9-2a3f7e18dbea',
    },
    minPlayers: 2,
    isPlayable: true,
  },
  kahootstarwars: {
    id: 'kahootstarwars', // Make sure this is unique and lowercase
    name: 'Star Wars Kahoot!',
    thumbnail: thumb_kahootstarwars,
    bg: bg_kahootstarwars,
    tags: 'Family, Educational, Sci-Fi',
    description: 'Test your Star Wars knowledge through Kahoot! prove that you are a real fan.',
    duration: '10-15 Min, 18 Questions',
    launchType: 'script',
    launchScriptId: 'kahoot',
    launchScriptArg: {
      url: 'https://play.kahoot.it/v2/?quizId=d55d2b10-9fc4-4bcc-ad2e-6bae84dee41b',
    },
    maxPlayers: 10,
    minPlayers: 2,
    isPlayable: true,
  },
  kahootworldcup: {
    id: 'kahootworldcup', // Make sure this is unique and lowercase
    name: 'World Cup Kahoot!',
    thumbnail: thumb_kahootworldcup,
    bg: bg_kahootworldcup,
    tags: 'Family, Educational, Sports',
    description: 'Get ready for the world cup! Have fun and get to know more about football history',
    duration: '10-15 Min, 12 Questions',
    launchType: 'script',
    launchScriptId: 'kahoot',
    launchScriptArg: {
      url: 'https://play.kahoot.it/v2/?quizId=fd8751e5-43f8-48a4-bc0e-e8519322be15',
    },
    maxPlayers: 10,
    minPlayers: 2,
    isPlayable: true,
  },
  kahootmarvel: {
    id: 'kahootmarvel', // Make sure this is unique and lowercase
    name: 'Marvel Kahoot!',
    thumbnail: thumb_kahootmarvel,
    bg: bg_kahootmarvel,
    tags: 'Family, Educational',
    description: 'Prove Your Knowledge with this Marvel Movie Quiz!',
    maxPlayers: 10,
    duration: '15 Min, 22 Questions',
    launchType: 'script',
    launchScriptId: 'kahoot',
    launchScriptArg: {
      url: 'https://play.kahoot.it/v2/?quizId=5705a72c-1eab-4786-8fb5-69a4269d6479',
    },
    minPlayers: 2,
    isPlayable: true,
  },
  pandemic: {
    id: 'pandemic', // Make sure this is unique and lowercase
    name: 'Pandemic',
    thumbnail: thumb_pandemic,
    bg: bg_pandemic,
    tags: 'Co-Op, Families, Friends',
    description: 'Your team of experts must prevent the world from succumbing to a viral pandemic.',
    maxPlayers: 4,
    minPlayers: 1,
    duration: '30-45 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  poker: {
    id: 'poker', // Make sure this is unique and lowercase
    name: 'Magic Poker',
    thumbnail: thumb_poker,
    bg: bg_poker,
    logo: logo_magicpoker,
    tags: 'Friends, Classic',
    description: 'Join the ultimate Texas Holdem poker night gathering! Play with friends or bots.',
    maxPlayers: 10,
    minPlayers: 1,
    duration: '15-60 Min',
    launchType: 'bgio',
    isPlayable: true,
  },
  risk: {
    id: 'risk', // Make sure this is unique and lowercase
    name: 'Risk',
    thumbnail: thumb_risk,
    bg: bg_risk,
    tags: 'Friends & Families, Classic, Strategy',
    description: 'Will you conquer the world in this multi-player push-your-luck wargame?',
    minPlayers: 1,
    maxPlayers: 10,
    duration: '60-120 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  sevenwonders: {
    id: 'sevenwonders', // Make sure this is unique and lowercase
    name: '7 Wonders',
    thumbnail: thumb_sevenwonders,
    bg: bg_sevenwonders,
    tags: 'Board-Gamers, Strategy',
    description: 'Draft cards to develop your ancient civilization and build its Wonder of the World.',
    minPlayers: 1,
    maxPlayers: 7,
    duration: '30 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  sevenwondersduel: {
    id: 'sevenwondersduel', // Make sure this is unique and lowercase
    name: '7 Wonders Duel',
    thumbnail: thumb_sevenwondersduel,
    bg: bg_sevenwondersduel,
    tags: 'Couples, Strategy',
    description: 'Science? Military? What will you draft to win this head-to-head version of 7 Wonders?',
    minPlayers: 2,
    maxPlayers: 2,
    duration: '30 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  takenoko: {
    id: 'takenoko', // Make sure this is unique and lowercase
    name: 'Takenoko',
    thumbnail: thumb_takenoko,
    bg: bg_takenoko,
    tags: 'Families, Strategy',
    description: 'Carefully cultivate a bamboo garden with the help of a gardener and a hungry panda.',
    minPlayers: 1,
    maxPlayers: 4,
    duration: '45 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  'ticket-to-ride': {
    id: 'ticket-to-ride', // Make sure this is unique and lowercase
    name: 'Ticket To Ride',
    thumbnail: thumb_ticket_to_ride,
    bg: bg_ticket_to_ride,
    logo: logo_titckettoride,
    tags: 'Easy To Learn, Families, Couples With AI',
    description: 'An award winning, cross-country train adventure game.',
    minPlayers: 1,
    maxPlayers: 5,
    duration: '30 Min',
    launchType: 'bgio',
    isPlayable: false,
  },
  uno: {
    id: 'uno', // Make sure this is unique and lowercase
    name: 'Uno',
    thumbnail: thumb_uno,
    bg: bg_uno,
    tags: 'Easy To Learn, Classic, Families',
    description: 'Get rid of your cards first, but don\'t forget to say "UNO!"',
    minPlayers: 1,
    maxPlayers: 10,
    duration: '30 Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  justdancenow: {
    id: 'justdancenow', // Make sure this is unique and lowercase
    name: 'Just Dance Now',
    thumbnail: thumb_justdancenow,
    bg: bg_justdancenow,
    tags: 'Casual, Active, Families, Friends',
    description:
      'Looking for the perfect game to dance to the latest hits and share some fun with friends and family? Just Dance is for you!',
    minPlayers: 1,
    maxPlayers: 6,
    duration: '5+ Min',
    launchType: 'local',
    isPlayable: false,
  },
  selfiegames: {
    id: 'selfiegames', // Make sure this is unique and lowercase
    name: 'Selfie Games',
    thumbnail: thumb_selfiegames,
    bg: bg_selfiegames,
    logo: logo_selfiegames,
    tags: 'Casual, Families, Friends',
    description: 'Each player uses their phone to snap selfies, draw something, write, guess, and more',
    maxPlayers: 10,
    duration: '10-20 Min',
    launchType: 'script',
    launchScriptId: 'goAndClick',
    launchScriptArg: { url: 'https://screen.selfiegames.tv' },
    controllerURL: 'https://play.selfiegames.tv',
    controllerArgs: ['noIframe'],
    minPlayers: 3,
    isPlayable: true,
  },
  sushigo: {
    id: 'sushigo', // Make sure this is unique and lowercase
    name: 'Sushi Go!',
    thumbnail: thumb_sushigo,
    bg: bg_sushigo,
    tags: 'Casual, Families, Simultaneous',
    description: 'Pass the sushi around, but keep the best for yourself. Save room for dessert!',
    minPlayers: 2,
    maxPlayers: 5,
    duration: '15+ Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  forbiddenisland: {
    id: 'forbiddenisland', // Make sure this is unique and lowercase
    name: 'Forbidden Island',
    thumbnail: thumb_forbiddenisland,
    bg: bg_forbiddenisland,
    tags: 'Co-Op, Families, Friends',
    description: 'The island is sinking! Will the brave adventurers save the treasures in time?',
    minPlayers: 2,
    maxPlayers: 4,
    duration: '30+ Min',
    launchType: 'placeholder',
    isPlayable: false,
  },
  wreckless: {
    id: 'wreckless', // Make sure this is unique and lowercase
    name: 'Wreckless',
    thumbnail: thumb_wreckless,
    bg: bg_wreckless,
    tags: 'board, Casual, Families, Friends',
    description: 'Match a rider with vehicle and race your friends to win ',
    minPlayers: 1,
    maxPlayers: 6,
    duration: '20-35 Min',
    launchType: 'bgio',
    isPlayable: true,
  },
  doodledash: {
    id: 'doodledash', // Make sure this is unique and lowercase
    name: 'Doodle Dash',
    thumbnail: thumb_doodledash,
    bg: bg_doodledash,
    logo: logo_doodledash,
    tags: 'Social, Party, Casual, family',
    description:
      'Doodle Dash is the ultimate drawing and guessing game! Perfect for couples, families, and house parties.',
    minPlayers: 1,
    maxPlayers: 10,
    duration: '10-20 Min',
    launchType: 'bgio',
    isPlayable: true,
  },
  magicblocks: {
    id: 'magicblocks', // Make sure this is unique and lowercase
    name: 'Magic Blocks',
    thumbnail: thumb_magicblocks,
    bg: bg_magicblocks,
    logo: logo_magicblocks,
    tags: 'Puzzle, Casual, Family',
    description: 'Fit your tiles on a shared board with not enough space for everyone.',
    maxPlayers: 4,
    duration: '15-40 Min',
    minPlayers: 1,
    launchType: 'bgio',
    isPlayable: true,
  },
  magicsnakes: {
    id: 'magicsnakes', // Make sure this is unique and lowercase
    name: 'Magic snakes',
    thumbnail: thumb_magicblocks,
    bg: bg_magicblocks,
    logo: logo_magicblocks,
    tags: 'Social, Party, Casual, Action',
    description: 'Survive as long as you can while avoiding yours, and others, ever growing tail.',
    maxPlayers: 10,
    duration: '5-20 Min',
    minPlayers: 1,
    launchType: 'bgio',
    isPlayable: true,
  },
  gptrivia: {
    id: 'gptrivia', // Make sure this is unique and lowercase
    name: 'GPTrivia',
    thumbnail: thumb_gptrivia,
    bg: bg_gptrivia,
    logo: logo_gptrivia,
    tags: 'Social, Trivia, AI',
    description: 'A Trivia game with ChatGPT! Any Topic, Any Time, Infinite Fun!',
    maxPlayers: 10,
    duration: '5-10 Min',
    minPlayers: 1,
    launchType: 'bgio',
    isPlayable: true,
  },
};

const VARIANTS: VariantsData = {
  'ticket-to-ride': {
    dev: {
      extras: {},
      isPlayable: true,
      name: 'Ticket to Ride - Alpha',
    },
  },
  gptrivia: {
    sports: {
      name: 'Sports Trivia',
      extras: { category: 'Sports' },
      thumbnail: thumb_triviasports,
      bg: bg_triviasports,
    },
    disney: {
      name: 'Disney Trivia',
      extras: { category: 'Disney' },
      thumbnail: thumb_triviadisney,
      bg: bg_triviadisney,
    },
    history: {
      name: 'History Trivia',
      extras: { category: 'History' },
      thumbnail: thumb_triviahistory,
      bg: bg_triviahistory,
    },
    marvel: {
      name: 'Marvel Trivia',
      extras: { category: 'Marvel' },
      thumbnail: thumb_triviamarvel,
      bg: bg_triviamarvel,
    },
    music: {
      name: 'Music Trivia',
      extras: { category: 'Music' },
      thumbnail: thumb_triviamusic,
      bg: bg_triviamusic,
    },
    nba: {
      name: 'NBA Trivia',
      extras: { category: 'NBA' },
      thumbnail: thumb_trivianba,
      bg: bg_trivianba,
    },
    starwars: {
      name: 'Star Wars Trivia',
      extras: { category: 'Star Wars' },
      thumbnail: thumb_triviastarwars,
      bg: bg_triviastarwars,
    },
    wildlife: {
      name: 'Wild Life Trivia',
      extras: { category: 'Wildlife' },
      thumbnail: thumb_triviawildlife,
      bg: bg_triviawildlife,
    },
    soccer: {
      name: 'Soccer Trivia',
      extras: { category: 'Soccer' },
      thumbnail: thumb_triviasoccer,
      bg: bg_triviasoccer,
    },
    celebrity: {
      name: 'Celebrity Trivia',
      extras: { category: 'Celebrities' },
      thumbnail: thumb_triviacelebrity,
      bg: bg_triviacelebrity,
    },
    harrypotter: {
      name: 'Harry Potter Trivia',
      extras: { category: 'Harry Potter' },
      thumbnail: thumb_triviaharrypotter,
      bg: bg_triviaharrypotter,
    },
  },
};

// ****************  FUNCTIONS  **************** //

export const parseGameId = (
  yardGameId: string
): { baseGameId: BaseGameID; variantId: VariantID<GameIDThatHasVariants> | undefined } => {
  const [baseGameId, variantId] = yardGameId.split(VARIANT_DELIMITER) as [
    BaseGameID,
    VariantID<BaseGameID> | undefined
  ];
  assert(baseGameId in GAMES, `No such baseGameId: ${baseGameId}`);
  if (baseGameId in VARIANTS && variantId !== undefined) {
    assert(
      variantId in VARIANTS[baseGameId as GameIDThatHasVariants],
      `No variant ${variantId} for baseGameID ${baseGameId}`
    );
  }
  return { baseGameId: baseGameId, variantId: variantId };
};

export const getGameFromId = (gameId: string): Game => {
  const { baseGameId, variantId } = parseGameId(gameId);
  if (variantId !== undefined) {
    const gameWithVariant = {
      ...GAMES[baseGameId],
      ...getVariant(baseGameId as GameIDThatHasVariants, variantId),
      ...{ id: gameId as GameID, baseId: baseGameId },
    };
    return gameWithVariant as GameWithVariant;
  }
  return { ...GAMES[baseGameId], ...{ id: baseGameId, baseId: baseGameId } };
};

// ****************  TYPES  **************** //

export type BaseGameID = keyof typeof GAME_IDS_WITH_VARIANTS;

type VariantID<G extends BaseGameID> = (typeof GAME_IDS_WITH_VARIANTS)[G][number];

export const VARIANT_DELIMITER = '$$' as const;

type ValidIDForGame<G extends BaseGameID = BaseGameID> =
  | (VariantID<G> extends never ? never : `${G}${typeof VARIANT_DELIMITER}${VariantID<G>}`)
  | `${G}`;

type Variant = Partial<BaseGame> & { extras: any };
// Extra credit, make this less repetitive but still readable and correct
type GameWithoutVariant = BaseGame & { id: BaseGameID; baseId: BaseGameID };
type GameWithVariant = BaseGame & { id: GameID; baseId: BaseGameID } & Variant;
export type Game = GameWithoutVariant | GameWithVariant;

type GameIDThatHasVariants = {
  [G in BaseGameID]: VariantID<G> extends never ? never : G;
}[BaseGameID];

type VariantsData = {
  [G in GameIDThatHasVariants]: { [V in VariantID<G>]: Variant };
};

export type GameID = {
  [G in BaseGameID]: ValidIDForGame<G>;
}[BaseGameID];

interface BaseGame {
  id: BaseGameID;
  name: string;
  thumbnail: string;
  bg: string;
  logo?: string;
  tags: string;
  description: string;
  duration: string;
  launchType: 'script' | 'bgio' | 'local' | 'placeholder';
  launchScriptId?: 'kahoot' | 'wos' | 'goToURL' | 'goAndClick';
  launchScriptArg?: object;
  controllerURL?: string;
  controllerArgs?: ReadonlyArray<'noIframe' | 'example'>;
  minPlayers: number;
  maxPlayers: number;
  isPlayable: boolean;
  magicyardGame?: { importPath: string };
}

// ****************  FOR EXPORTING  **************** //

function* allGameIDs(): Generator<GameID> {
  for (const baseGameId in GAME_IDS_WITH_VARIANTS) {
    // Yield the base game ID
    yield baseGameId as GameID;

    // Yield each variant ID
    for (const variantId of GAME_IDS_WITH_VARIANTS[baseGameId as BaseGameID]) {
      const gameIdWithVariant = `${baseGameId}${VARIANT_DELIMITER}${variantId}` as GameID;
      yield gameIdWithVariant;
    }
  }
}

function getVariant<G extends GameIDThatHasVariants>(baseGameId: G, variantId: VariantID<G>): Variant {
  if (variantId in VARIANTS[baseGameId]) {
    return VARIANTS[baseGameId][variantId];
  }
  throw new Error(`No variant ${variantId} for game ${baseGameId}`);
}

// To run, change all image imports to const <imageName> = './assets/path'
// Then run `yarn export:games-data` in this directory
export function dumpGamesWithVariants() {
  const DATA_BY_GAME_ID_WITH_VARIANT = Array.from(allGameIDs()).reduce<Record<GameID, Game>>((acc, gameId) => {
    return {
      ...acc,
      [gameId]: getGameFromId(gameId),
    };
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
  }, {} as Record<GameID, Game>);
  return DATA_BY_GAME_ID_WITH_VARIANT;
}

export const getGamePlayersString = (game: Game) => {
  return game.minPlayers === game.maxPlayers ? `${game.minPlayers}` : `${game.minPlayers}-${game.maxPlayers}`;
};
