import { GameID, getGameFromId, VARIANT_DELIMITER } from './games';

export interface Row {
  title: string;
  items: GameID[];
}

export const getRows = (showDev: boolean = false): Row[] => {
  const rows: Row[] = [
    {
      title: 'Highlights',
      items: ['gptrivia', 'magicblocks', 'doodledash', 'poker', 'wordsonstream', 'selfiegames'],
    },
    {
      title: 'Trivia',
      items: [
        `gptrivia${VARIANT_DELIMITER}harrypotter`,
        `gptrivia${VARIANT_DELIMITER}marvel`,
        `gptrivia${VARIANT_DELIMITER}disney`,
        `gptrivia${VARIANT_DELIMITER}starwars`,
        `gptrivia${VARIANT_DELIMITER}wildlife`,
        `gptrivia${VARIANT_DELIMITER}celebrity`,
        `gptrivia${VARIANT_DELIMITER}history`,
        `gptrivia${VARIANT_DELIMITER}sports`,
        `gptrivia${VARIANT_DELIMITER}nba`,
        `gptrivia${VARIANT_DELIMITER}soccer`,
      ],
    },
    /* {
      title: 'Kahoots',
      items: ['kahootmarvel', 'kahootdisney', 'kahootstarwars', 'kahootnba', 'kahootworldcup', 'kahootanimals'],
    }, */
    {
      title: 'Coming soon',
      items: showDev
        ? ['explodingkittens', 'takenoko', 'sevenwonders', 'dixit']
        : ['explodingkittens', 'ticket-to-ride', 'takenoko', 'sevenwonders', 'dixit'],
    },
  ];

  const underDevelopment: Row = {
    title: 'Early Access',
    items: [`ticket-to-ride${VARIANT_DELIMITER}dev`, 'magicsnakes'],
  };

  if (showDev) {
    // const trivia = rows[1];
    // rows[1] = rows[2];
    // rows[2] = underDevelopment;
    rows[3] = underDevelopment;
  }

  return rows;
};

export const preloadAllRowContent = () => {
  for (const r of getRows()) {
    r.items.forEach((x) => {
      const linkBg = document.createElement('link');
      linkBg.rel = 'preload';
      linkBg.as = 'image';
      linkBg.href = getGameFromId(x).bg;
      document.head.appendChild(linkBg);
      const linkImg = document.createElement('link');
      linkImg.rel = 'preload';
      linkImg.as = 'image';
      linkImg.href = getGameFromId(x).thumbnail;
      document.head.appendChild(linkImg);
    });
  }
};
