import { EnterPressHandler, FocusHandler, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import { useEffect, useState } from 'react';
import { Game } from '@magicyard/platform-shared/games';
import './NavigationItem.css';
import { usePreloadImage } from './GameDetails';

interface NavigationItemProps {
  game: Game;
  onEnterPress: EnterPressHandler<{ game: Game }>;
  onFocus: FocusHandler<{ game: Game; isInitialFocus: boolean }>;
  isDefault: boolean;
  isRowFocused: boolean;
}

export function NavigationItem({ game, onEnterPress, onFocus, isDefault, isRowFocused }: NavigationItemProps) {
  const [didAlreadyFocus, setDidAlreadyFocus] = useState(false);
  const { ref, focused, focusSelf } = useFocusable({
    onEnterPress,
    onFocus,
    extraProps: { game, isInitialFocus: isDefault && !didAlreadyFocus },
  });

  useEffect(() => {
    if (isDefault) {
      focusSelf();
      setDidAlreadyFocus(true);
    }
  }, [focusSelf, isDefault]);

  return (
    <div className={`navigation_item-root`} ref={ref}>
      <div
        className={`navigation_item-item ${focused ? 'navigation_item-item_focused' : ''}`}
        style={{
          backgroundImage: `url(${game.thumbnail})`,
        }}
      />
    </div>
  );
}
