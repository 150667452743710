import { Game, getGamePlayersString } from '@magicyard/platform-shared/games';
import { Display, generateRandomControllers, Yard, YardWithGame } from '@magicyard/shared/platform/lib/api';
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { CSSProperties, useEffect, useState } from 'react';
import { URL_SHORTENING_API } from '@magicyard/shared/platform/lib/urlShortener';
import { getControllerUrlConnectDisplay } from '@magicyard/utils';
import './GameDetails.css';
import { BaseController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';
import { Communication } from '@magicyard/shared/platform/hooks/usePlatformDisplayTypes';
import { useReaction } from '../CommunicationContainer';
import { track } from '@magicyard/shared/src/analytics';
import addPhoneIcon from '../assets/addPhoneSmall.png';
import { Textfit } from 'react-textfit';

export const usePreloadImage = (src: string) => {
  const [sourceLoaded, setSourceLoaded] = React.useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSourceLoaded(src);
  }, [src]);

  return sourceLoaded;
};

export const GameDetails = ({
  highlighted,
  show,
  selected,
}: {
  highlighted: Game;
  show: boolean;
  selected: boolean;
}) => {
  const [bg, setBg] = useState(highlighted.bg);

  useEffect(() => {
    const id = window.setTimeout(() => {
      setBg(highlighted.bg);
    }, 300);

    return () => {
      window.clearTimeout(id);
    };
  }, [highlighted.bg]);
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${bg})`,
        }}
        key={bg}
        className={`game_details-bg ${show ? '' : 'game_details-root_hide'} ${
          selected ? 'game_details-bg-selected' : ''
        }`}
      />
      <div className={`game_details-bg-gradient ${selected ? 'game_details-bg-gradient-selected' : ''}`} key={1} />
    </>
  );
};

export function GameDetailsSelected({
  game,
  yard,
  display,
  communication,
}: {
  game: Game;
  yard: YardWithGame | Yard;
  display: Display;
  communication: Communication;
}) {
  const { ref } = useFocusable({
    focusKey: 'GAME_DETAILS',
  });
  useEffect(() => {
    track('Yard And QR Loaded');
  }, [game]);
  useEffect(() => {
    if (yard.controllers.length > 0) {
      track('Yard With Players Loaded');
    }
  }, [yard.controllers.length]);

  if (!game.isPlayable) {
    return (
      <div className={`game_details-root`} ref={ref}>
        <div className={'game_details_selected-root_coming_soon'}>{game.name} is coming soon</div>
      </div>
    );
  }
  return (
    <div className={`game_details-root`} ref={ref}>
      <div className={'game_details_selected-root'}>
        <div className={'game_details-selected-root_radial'} />
        <div className={'game_details-game-title'}>
          {game.logo === undefined ? (
            game.name
          ) : (
            <div className={'game_details-game-title-image'} style={{ backgroundImage: `url(${game.logo})` }} />
          )}
        </div>
        <div className={'game_details-title-container'}>
          <div className={'game_details-title'}>To Play, scan the QR code. Your phone will become the controller!</div>
          <div className={'game_details-subtitle'}>
            Or open <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>magicyard.tv</span> and enter room
            code:
            <span style={{ fontWeight: 'bold' }}>{' ' + display.code}</span>
          </div>
        </div>
        <div className={'game_details-body'}>
          <QrWithIcon display={display} title={''} />
          <div className={'game_details-block-with-icon'}>
            <div className={'game_details-block game_details_selected-yard_container'}>
              <div className={'game_details_selected-yard_body'}>
                {yard.controllers.length === 0 && <div className={'game_details-add_phone'} />}
                {/*<div className={'game_details_selected-body_title'}>*/}
                {/*  Playing {game.name}*/}
                {/*  <div className={'game_details-people'} />*/}
                {/*  {getGamePlayersString(game)}*/}
                {/*  <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end', color: '#fa5d5d' }}>*/}
                {/*    {game.minPlayers !== undefined &&*/}
                {/*    yard.controllers.length > 0 &&*/}
                {/*    yard.controllers.length < game.minPlayers*/}
                {/*      ? `Requires ${game.minPlayers - yard.controllers.length} more players`*/}
                {/*      : ''}*/}
                {/*  </div>*/}
                {/*</div>*/}
                {yard.controllers.concat(generateRandomControllers(0)).map((controller) => {
                  return (
                    <PlayerPart
                      horizontalName={false}
                      key={controller.profile.id}
                      controller={controller}
                      yard={yard}
                      communication={communication}
                      size={'md'}
                    />
                  );
                })}
                {yard.controllers.length > 0 && (
                  <PlayerPart
                    horizontalName={false}
                    key={-999}
                    controller={{ profile: { name: '', avatarUrl: addPhoneIcon, id: '-999' }, isOnline: true }}
                    yard={yard}
                    communication={communication}
                    size={'md'}
                  />
                )}
              </div>
            </div>
            <div className={'game_details-icon_with_text'}>
              <div className={'game_details-profile'} />
              Connected players
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const QrWithIcon = ({ display, title }: { display: Display; title: React.ReactNode }) => {
  return (
    <div className={`game_details-block-with-icon`}>
      <div className={'game_details-block'}>
        <QrWithPlaceholder display={display} title={title} />
      </div>
      <div className={'game_details-icon_with_text'}>
        <div className={'game_details-phone'} />
        Scan with your camera
      </div>
    </div>
  );
};

export const QrWithPlaceholder = ({ display, title }: { display: Display; title: React.ReactNode }) => {
  return (
    <div className={'game_details_selected-qr-with-placehoder-root'}>
      <div className={'game_details_selected-qr-with-placehoder-qr game_details_selected-fake_qr'} />
      <div
        className={'game_details_selected-qr-with-placehoder-qr'}
        style={{
          backgroundImage: `url(${URL_SHORTENING_API}/qr?text=${encodeURIComponent(
            getControllerUrlConnectDisplay(display.id)
          )})`,
        }}
      >
        <div className={'game_details_selected-qr-text'}>{title}</div>
      </div>
    </div>
  );
};

export function GameDetailsText({ game, style }: { game: Game; style?: CSSProperties }) {
  return (
    <div className={'game_details_text'} style={style}>
      <div style={{ fontSize: 70, fontWeight: 'bold' }}>{game.name}</div>
      <div style={{ display: 'flex' }}>
        {game.tags.split(', ').map((t, i) => (
          <div className={'game_details-tag'} key={t} style={{ marginLeft: i === 0 ? 0 : 5 }}>
            {t}
          </div>
        ))}
      </div>
      <div style={{ marginTop: 32, fontSize: '32px' }}>{game.description}</div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 32, fontSize: 32 }}>
        <div className={'game_details-sand_clock'} />
        {game.duration}
        <div className={'game_details-people'} />
        {getGamePlayersString(game)}
      </div>
    </div>
  );
}

export const PlayerPart = ({
  controller,
  yard,
  communication,
  size,
  horizontalName,
}: {
  controller: BaseController;
  yard: YardWithGame | Yard;
  communication: Communication;
  size: 'sm' | 'md';
  horizontalName: boolean;
}) => {
  const isHost = (yard.host ?? yard.controllers[yard.controllers.length - 1])?.profile.id === controller.profile.id;
  const speakerData = yard.voiceChatState?.speakers.find((y) => y.id === controller.profile.id);
  const { reactionForKey, deleteReactionForKey } = useReaction(communication.receiveReaction);
  return (
    <div className={!horizontalName ? 'game_details-avatar-root' : ''}>
      {!horizontalName && isHost && <div className={'game_details-host'}>👑</div>}
      <div
        className={`game_details-avatar-container game_details-avatar-container-${size} ${
          horizontalName ? '' : 'game_details-avatar-container-vertical'
        }`}
        key={controller.profile.id}
      >
        <div
          className={`game_details-avatar game_details-avatar-${size}  ${
            speakerData === undefined
              ? ''
              : speakerData.muted
              ? 'game_details-avatar-container-speaker-muted'
              : 'game_details-avatar-container-speaker'
          }`}
          style={{ backgroundImage: `url(${controller.profile.avatarUrl})` }}
        />
        {Object.keys(reactionForKey).map((key) => {
          const curr = reactionForKey[key];
          if (curr.reaction.controller_id === controller.profile.id) {
            return (
              <div
                key={key}
                className={'game_details-avatar-reaction'}
                style={{ position: 'absolute' }}
                onAnimationEnd={() => deleteReactionForKey(key)}
              >
                {reactionForKey[key].reaction.payload}
              </div>
            );
          }
          return undefined;
        })}
        {!horizontalName ? (
          <Textfit style={{ height: 35, width: '100%', textAlign: 'center' }}>{controller.profile.name}</Textfit>
        ) : (
          `${controller.profile.name} ${isHost ? '👑' : ''}`
        )}
      </div>
    </div>
  );
};
