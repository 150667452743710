import { Game } from '@magicyard/platform-shared/games';
import { getRows } from '@magicyard/platform-shared/rows';
import { FocusContext, FocusHandler, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { useCallback, useState } from 'react';
import { GameDetailsText } from './GameDetails';
import { ContentRow } from './NavigationRow';
import { track, updateAnalyticsData } from '@magicyard/shared/src/analytics';

export function Content({
  contentVisible,
  highlighted,
  setHighlighted,
  onSelect,
  showDev,
}: {
  contentVisible: boolean;
  highlighted: Game | null;
  setHighlighted: (game: Game | null) => void;
  onSelect: (asset: { game: Game }) => void;
  showDev: boolean;
}) {
  const { focusKey, ref } = useFocusable({ focusKey: 'CONTENT' });
  const onAssetFocus = useCallback<FocusHandler<{ game: Game }>>((layout, props, details) => {
    updateAnalyticsData({ gameId: props.game.id });
    track('Game Focused');
    setHighlighted(props.game);
  }, []);

  const [y, setY] = useState(0);

  const onRowFocus = useCallback(
    ({ y }: { y: number }) => {
      setY(y);
    },
    [setY]
  );

  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <div
          ref={ref}
          className={'navigation-content'}
          style={{
            opacity: contentVisible ? 1 : 0,
          }}
        >
          {highlighted !== null && (
            <GameDetailsText
              game={highlighted}
              style={{
                transform: contentVisible ? undefined : 'translate3d(0,-60%,0.1px)',
                transition: '0.3s',
                height: '100%',
              }}
            />
          )}
          <div className={'navigation-rows'} style={{ transform: contentVisible ? '' : 'translate3d(0,60%,0.1px)' }}>
            <div
              style={{
                transition: '0.3s transform',
                transform: `translate3d(0,${-y}px,0.1px)`,
                willChange: 'transform',
              }}
            >
              {getRows(showDev).map(({ title, items }, i) => (
                <ContentRow
                  data={items}
                  key={title}
                  title={title}
                  onAssetFocus={onAssetFocus}
                  onAssetPress={onSelect}
                  onFocus={onRowFocus}
                  initialAsset={null} // TODO temp for performance highlighted
                />
              ))}
            </div>
          </div>
        </div>
      </FocusContext.Provider>
    </>
  );
}
