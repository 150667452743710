import { EnterPressHandler, FocusContext, FocusHandler, useFocusable } from '@noriginmedia/norigin-spatial-navigation';
import React, { CSSProperties, useCallback, useRef, useState } from 'react';
import { Game, GameID, getGameFromId } from '@magicyard/platform-shared/games';
import './NavigationRow.css';
import { NavigationItem } from './NavigationItem';

interface ContentRowProps {
  title: string;
  onAssetPress: EnterPressHandler<{ game: Game }>;
  onFocus: FocusHandler<{ game: Game }>;
  onAssetFocus: FocusHandler<{ game: Game }>;
  data: readonly GameID[];
  initialAsset: Game | null;
  style?: CSSProperties;
}

export function ContentRow({
  data,
  title: rowTitle,
  onAssetPress,
  onFocus,
  onAssetFocus,
  initialAsset,
}: ContentRowProps) {
  const { ref, focusKey, hasFocusedChild } = useFocusable({
    onFocus,
    trackChildren: true,
  });

  const scrollingRef = useRef<HTMLDivElement | null>(null);
  const [x, setX] = useState({ x: 0, noTransition: initialAsset === null });
  const handleAssetFocus = useCallback<FocusHandler<{ game: Game; isInitialFocus: boolean }>>(
    (layout, props, details) => {
      if (scrollingRef.current === null) {
        return;
      }
      setX({ x: layout.x, noTransition: props.isInitialFocus });
      onAssetFocus(layout, props, details);
    },

    [onAssetFocus]
  );

  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} className={'navigation_row-root'}>
        <div className={'navigation_row-title'}>{rowTitle}</div>
        <div
          style={{
            transition: '0.3s transform', //x.noTransition ? undefined :
            transform: `translate3d(${-x.x + (x.x === 0 ? 0 : 60)}px,0,0.1px)`,
          }}
        >
          <div ref={scrollingRef}>
            <div
              className={'navigation_row-content'}
              // style={{ transition: 'opacity 0s 0.4s', opacity: hasFocusedChild ? 1 : 0.3 }}
            >
              {data.map((game, i) => (
                <NavigationItem
                  key={game}
                  game={getGameFromId(game)}
                  onEnterPress={onAssetPress}
                  onFocus={handleAssetFocus}
                  isDefault={initialAsset?.id === game}
                  isRowFocused={hasFocusedChild}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </FocusContext.Provider>
  );
}
