import { useCallback, useState } from 'react';
import { Game } from '@magicyard/platform-shared/games';
import { FocusContext, useFocusable, pause, resume } from '@noriginmedia/norigin-spatial-navigation';
import { Display, Yard, YardWithGame } from '@magicyard/shared/platform/lib/api';
import { Communication } from '@magicyard/shared/platform/hooks/usePlatformDisplayTypes';
import features from '../features.json';
import { PlayerPart } from './GameDetails';
import JoinOrInviteScreen from './JoinOrInviteScreen';

interface MenuProps {
  yard: Yard | YardWithGame;
  communication: Communication;
  setContentVisible: (visible: boolean) => void;
  contentVisible: boolean;
  selected: Game | null;
  onFocus: () => void;
  display: Display;
}

export function Menu({
  yard,
  communication,
  setContentVisible,
  contentVisible,
  onFocus,
  display,
  selected,
}: MenuProps) {
  const [joinOrInviteVisible, setJoinOrInviteVisible] = useState(false);
  const { ref, hasFocusedChild, focusKey } = useFocusable({
    focusable: false, // TODO when social is back change
    saveLastFocusedChild: false,
    trackChildren: true,
    autoRestoreFocus: true,
    onFocus: onFocus,
  });

  const showFriendsTab = false;

  const handleInviteFriendsSelected = useCallback(() => {
    setContentVisible(false);
    setJoinOrInviteVisible(true);
    pause();
  }, [setContentVisible, pause]);

  const handleJoinOrInviteBack = useCallback(() => {
    setContentVisible(true);
    setJoinOrInviteVisible(false);
    resume();
  }, [resume, setContentVisible]);

  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <div
          className={`navigation-menu ${hasFocusedChild ? 'navigation-menu_active' : ''}`}
          style={{ transform: !contentVisible && !showFriendsTab ? 'translateX(-100%)' : undefined }}
          ref={ref}
        >
          <div className={'navigation-menu_yard'}>
            <div className={'navigation-menu_yard_title'}>My Yard</div>
            <div className={'navigation-menu_yard_body'}>
              {yard.controllers.map((controller) => {
                return (
                  <div className={'navigation-menu_yard_player'} key={controller.profile.id}>
                    <PlayerPart
                      size={'sm'}
                      controller={controller}
                      yard={yard}
                      communication={communication}
                      horizontalName={true}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {features.joinOrInvite && <InviteFriendsMenuItem onSelect={handleInviteFriendsSelected} />}
        </div>
      </FocusContext.Provider>
      {joinOrInviteVisible && <JoinOrInviteScreen onBack={handleJoinOrInviteBack} display={display} />}
    </>
  );
}

const InviteFriendsMenuItem = ({ onSelect }: { onSelect: () => void }) => {
  const handleEnterPress = useCallback(() => {
    onSelect();
  }, [onSelect]);

  const { ref, focused } = useFocusable({ onEnterPress: handleEnterPress });

  return (
    <div className={`navigation-invite_friends ${focused ? 'navigation-invite_friends_active' : ''}`} ref={ref}>
      <div className={'navigation-invite_friends_content'} />
    </div>
  );
};
