import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './Navigation.css';
import { init, useFocusable, setFocus, setKeyMap } from '@noriginmedia/norigin-spatial-navigation';
import { Game, getGameFromId } from '@magicyard/platform-shared/games';
import { Display, NavigationCommand, Yard, YardWithGame } from '@magicyard/shared/platform/lib/api';
import { App } from '@capacitor/app';
import { GameDetails, GameDetailsSelected } from './GameDetails';
import { Communication } from '@magicyard/shared/platform/hooks/usePlatformDisplayTypes';
import { Dialog } from '@capacitor/dialog';
import magicYard from '../assets/magicyard.webp';
import { useBackButton } from './useBackButton';
import { Menu } from './Menu';
import { Content } from './Content';
import { track, updateAnalyticsData } from '@magicyard/shared/src/analytics';

init({
  throttle: 500,
});

const isSky = new URLSearchParams(window.location.search).get('version_name') === 'Android-SKY';

const SHOW_DEV_URL = new URLSearchParams(window.location.search).get('showDev') === '1' || isSky;

export function Navigation({
  display,
  yard,
  setGameId,
  isPlayingGame,
  communication,
}: {
  display: Display;
  yard: YardWithGame | Yard;
  setGameId: (id: string | null) => void;
  isPlayingGame: boolean;
  communication: Communication;
}) {
  useEffect(() => {
    const commandToEvent: Record<NavigationCommand['command'], KeyboardEvent> = {
      up: new KeyboardEvent('keydown', { bubbles: true, cancelable: true, key: 'ArrowUp', keyCode: 38 }),
      down: new KeyboardEvent('keydown', { bubbles: true, cancelable: true, key: 'ArrowDown', keyCode: 40 }),
      left: new KeyboardEvent('keydown', { bubbles: true, cancelable: true, key: 'ArrowLeft', keyCode: 37 }),
      right: new KeyboardEvent('keydown', {
        bubbles: true,
        cancelable: true,
        key: 'ArrowRight',
        keyCode: 39,
      }),
      enter: new KeyboardEvent('keydown', { bubbles: true, cancelable: true, key: 'Enter', keyCode: 13 }),
      back: new KeyboardEvent('keydown', { bubbles: true, cancelable: true, key: 'Backspace', keyCode: 8 }),
      toggleQr: null as any,
    };
    communication.receiveNavigationCommand((command) => {
      if (command.command === 'toggleQr') {
        return;
      }
      window.dispatchEvent(commandToEvent[command.command]);
    });
  }, []);

  const { ref } = useFocusable();

  const gameId = yard.type === 'withGame' ? yard.gameId : null;
  const initialSelection = useMemo(() => (gameId === null ? null : getGameFromId(gameId)), [gameId]);
  const [highlighted, setHighlighted] = useState<Game | null>(initialSelection);
  const [selected, setSelected] = useState<Game | null>(initialSelection);
  const [, setShowDevCounter] = useState(0);
  const [showDev, setShowDev] = useState(SHOW_DEV_URL);

  const isRenderingContent = !isPlayingGame && selected === null;
  const isRenderingMenu = yard.controllers.length > 0;
  const isRenderingGameDetails = highlighted !== null && !isPlayingGame;
  const isRenderingSelectedGame = selected !== null && !isPlayingGame;

  const handleBackButton = useCallback(() => {
    if (isRenderingSelectedGame || isPlayingGame) {
      if (isPlayingGame) {
        track('Game Exited');
      }
      setSelected(null);
      setGameId(null);
    } else {
      Dialog.confirm({
        title: 'Exit Magicyard?',
        message: 'Are you sure you want to close the app?',
      }).then((confirmResult) => {
        if (confirmResult.value) {
          App.exitApp();
        }
      });
    }
  }, [isRenderingSelectedGame, isPlayingGame, setGameId]);

  useBackButton(handleBackButton);

  // React to game changes from the server
  useEffect(() => {
    setSelected(initialSelection);
    if (initialSelection !== null) {
      setHighlighted(initialSelection);
    }
  }, [initialSelection]);

  const onSelect = useCallback(
    (asset: { game: Game }) => {
      setSelected(asset.game);
      setGameId(asset.game.id);
      updateAnalyticsData({ gameId: asset.game.id });
      track('Game Chosen');
      setFocus('GAME_DETAILS');
    },
    [setFocus, setGameId]
  );

  const focusContent = selected === null || highlighted === null;
  useEffect(() => {
    if (focusContent) {
      setFocus('CONTENT');
    }
  }, [focusContent, setFocus]);

  // Manually turn on showDev
  useEffect(() => {
    const cb = (e: KeyboardEvent) => {
      if (!showDev) {
        setShowDevCounter((currentCounter) => {
          if (e.key === 'ArrowUp') {
            if (currentCounter + 1 > 5) {
              setShowDev(true);
            }
            return currentCounter + 1;
          } else {
            return 0;
          }
        });
      }
    };

    window.addEventListener('keydown', cb);
    return () => {
      window.removeEventListener('keydown', cb);
    };
  }, [showDev]);

  return (
    <div className={'navigation-root'} ref={ref}>
      {/*{isSky && (*/}
      {/*  <div className={'navigation-slowdowns'}>*/}
      {/*    We are experiencing 3rd party slowdowns, we're working with providers to fix this.*/}
      {/*  </div>*/}
      {/*)}*/}
      {isRenderingMenu && (
        <Menu
          contentVisible={isRenderingContent}
          setContentVisible={() => undefined} // TODO
          selected={selected}
          yard={yard}
          communication={communication}
          onFocus={() =>
            setHighlighted({
              controllerArgs: undefined,
              controllerURL: '',
              duration: '30 seconds',
              isPlayable: true,
              launchType: 'script',
              minPlayers: 0,
              maxPlayers: 100,
              tags: 'Online',
              id: 'sushigo',
              baseId: 'sushigo',
              bg: magicYard,
              description: 'Play online with your friends!',
              name: 'Invite or join friends',
              thumbnail: '',
            })
          }
          display={display}
        />
      )}
      {isRenderingGameDetails && (
        <GameDetails highlighted={highlighted} show={true} selected={isRenderingSelectedGame} />
      )}
      {isRenderingSelectedGame && (
        <GameDetailsSelected game={selected} yard={yard} display={display} communication={communication} />
      )}
      <Content
        onSelect={onSelect}
        setHighlighted={(game) => {
          updateAnalyticsData({ gameId: game === null ? undefined : game.id });
          setHighlighted(game);
        }}
        highlighted={highlighted}
        contentVisible={isRenderingContent}
        showDev={showDev}
      />
    </div>
  );
}
