import './InitialLoading.css';
import Lottie from 'lottie-react';
import loading from './assets/loading.json';
import { track } from '@magicyard/shared/src/analytics';
import splash from './assets/splash.png';
import { useEffect, useState } from 'react';

export const InitialLoading = ({
  onClick,
  toTrack,
  showBar,
}: {
  onClick?: () => void;
  toTrack?: string;
  showBar?: boolean;
}) => {
  const [bars, setBars] = useState(0);
  useEffect(() => {
    const incBars = () => {
      setBars((old) => (old + 1) % 8);
    };
    const id = window.setInterval(incBars, 500);

    if (toTrack !== undefined) {
      track(toTrack);
    }
    return () => {
      window.clearInterval(id);
    };
  }, []);

  return (
    <Lottie
      animationData={loading}
      style={{ position: 'absolute', width: '100%', height: '100%', top: 0, zIndex: 1000, background: '#ff005d' }}
    >
      {onClick !== undefined && <div>Click to start!</div>}
      {showBar === true &&
        new Array(bars).fill(0).map((b, i) => (
          <div
            key={i}
            style={{
              position: 'absolute',
              bottom: 100,
              height: 40,
              width: 50,
              background: 'white',
              left: 700 + i * 70,
              borderRadius: 30,
            }}
          />
        ))}
    </Lottie>
  );
};
